//  cause Firefox & IE to make page breaks

@media print {
  .grid-x,
  .grid-y,
  .cell {
    display: block;
    overflow: visible !important;
  }
  .collapsible-object .collapsible-content {
    display: block !important;
    overflow: visible !important;
    page-break-after: always;
  }
}

// print in full-width columns
@media print {
  .grid-x > {
    div[class*="cell"] {
    width: 90%;
  }
  }
// reduce image sizes for print (parent includes classname that ends in "graphic")
 [class$="graphic"] {
   img {
     width: 60%;
   }

   div[class$="graphic"] [class="print-full"] img {
       width: 100%;
   }
 }
  img,
  img.w-full {
     width: 60%;
   }
}
