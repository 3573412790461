ul {
  list-style: square;
  &.ul-square {
    list-style: square;
  }
  &.ul-circle {
    list-style: circle;
  }
  &.ul-disc {
    list-style: disc;
  }
}

ul.ul-nested {
  text-indent: 0;
  list-style: disc;
  margin-left: $global-margin;
}

%dot,
.dot {
  color: $white;
  font-style: normal;
  background-color: $body-font-color;
  padding: .15rem .5rem;
  font-size: ($global-font-size * 1.25);
  border-radius: 100%;
}

ol.ol-dots {
  text-align: left;
  counter-reset: li;
  margin-left: 0;
  padding-left: 0;
  > li {
    position: relative;
    list-style: none;
    padding-bottom: $global-padding;
    &:before {
      content:counter(li);
      counter-increment: li;
      @extend %dot;
      margin-right: .5rem;
    }
  }
  li ol,
  li ul {
    list-style: none;
    text-indent: -1rem;
    margin-left: 1rem;
    margin-top: .5rem;
  }
  ol li:last-child {
    margin-bottom: 0;
  }
  li h5 {
    display: inline;
  }
  ul.ul-square {
    list-style: square;
    text-indent: 0;
    margin-left: 2rem;
  }
}

// indent text for larger font size

ol.ol-dots.lead > li {
    text-indent: -2.3rem;
    margin-left: 2.3rem;
}

%bullet,
.bullet {
  width: 1.2rem;
  height: 1.2rem;
  margin: .1rem;
  background-color: $medium-gray;
  border-radius: 100%;
}

.bullet-active {
  @extend %bullet;
  background-color: $dark-gray;
}
