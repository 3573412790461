// MUST @import at top of settings
// ----------------------------------------
// Color-Named Variables
// ----------------------------------------

$gray-haze: #a7a7a7;
$gray-fawn: #e4e1db;
$gray-green: #d1d8da;
$cucumber-green: #c6dbd1;
$coral-orange: #f5b9a1;
$maize-gold: #f3dba5;
$lemon-soft: #f9e7a5;

///

$light-mint-green: #cddbd8;
$mint-green: #b0cac5;
$dark-mint-green: #71a7a0;

$light-slate-gray: #aebac1;
$slate-gray: #89979e;
$dark-slate-gray: #3f4e54;

$light-navajo-white: #feefe2;
$navajo-white: #fde7d6;
$dark-navajo-white: #fcd7b8;

$xlight-pink: #fbdad1;
$light-pink: #f9c5b9;
$pink: #f7b1a2;
$dark-pink: #f39c8c;

$xlight-sky-blue: #ccd5e1;
$light-sky-blue: #aec2d3;
$sky-blue: #92afc4;
$dark-sky-blue: #739bb7;

$xlight-salmon: #fcd7bb;
$light-salmon: #f9c299;
$salmon: #f7ad77;
$dark-salmon: #f49657;

$bisque: #d4b69c;
$bisque-brown: #a68c83;
$bisque-brown-dark: #785246;

$xlight-golden: #fdedd4;
$light-golden: #fee5be;
$golden: #fed491;

$light-sea-green: #d6ddc3;
$sea-green: #c1cca4;
$dark-sea-green: #96ac6a;

$light-soft-rose: #eac5c1;
$soft-rose: #e0a7a2;
$rose: #ce686c;
$red-rose: #941e28;

$gray99: #ececec;
$gray84: #dadada;
$gray30: #636362;
$gray24: #575756;

///

$grayed-jade-xlight: #E7F1EE; // callout.primary
$grayed-jade-light: #D9F1E3; // top-bar-light
$grayed-jade-medium: #9AC1AC; // top-bar-dark
//$grayed-jade-dark: #22a28c;
$grayed-jade-dark: #1d8674;

$light-grass: #d3dfaf;
$light-sage: rgba(186, 209, 210, 0.42);
$light-green: #717c6d;
$forest-green: #2a3a37;

$dark-green-cyan: #00736b;
$darker-green-cyan: #004540;

$hs-logo-green: #00a25f;

$yellow-highlight: #fdfd98;
$yellow-highlight-soft: #ffffcc;
$pale-yellow: #ffffeb;

$hs-logo-orange: #f7961d;
$tangerine: #e39441;
$med-tangerine: #e3af4b;
$light-tangerine: #edc475;
$xlight-tangerine: #FDE9CF;
$gold: #c1923c;
$taupe: #b9af9b;
$xdark-khaki: #959287;
$dark-khaki: #c6c3b4;
$khaki: #E5E2DB;
$light-khaki: #f0ede6;
$xlight-khaki: #fafaf5;

$red: #eb3f24; // alert
$pastel-magenta-red: #f5989d;
$light-pastel-magenta-red: #f7ced8;
$xlight-pastel-magenta-red: #ffeef5;

$xlight-lavendar: rgba(223, 215, 255, 0.82);
$light-lavendar: #dcd4fc;
$pastel-violet: #b09cd7;
$lavendar: #554a7f;
$hs-logo-purple: #45328e;
$purple: #333366;
$grape: #58394b;

$navy-blue: #05294E; // tertiary-color
$cobalt-blue: #003366;  // header-color
$blue-slate-dark: #3e516f;  // font-color for hexagons 3e516f
$blue-slate: #56719a;
$blue-turquoise-dark: #3f6e81;
$blue-royal: #1b75bb;
$light-denim-blue: #c5daf8;
$xlight-denim-blue: rgba(202, 224, 254, 0.7);
$periwinkle: #9bbafd;
$xlight-turquoise: #dae6e9;
$xxlight-turquoise: #f1fdfa;
$sky-blue: rgb(196, 217, 236);
$light-sky-blue: #d3e9fa;
$baby-blue: #d6f1fe;

$light-slate-blue: #d2e3f3;
$xlight-slate-blue: #ECF2FF; // secondary-color


$white: #fefefe; // F6: white UI items : rgb(254,254,254)
$xxxlight-gray: #f8f9f9;
$xxlight-gray: #f1f2f2;
$xlight-gray: #eaebeb;
$xlight-med-gray: #eeefef;
$light-gray: #e1e2e2; // F6: light gray UI items
$xlight-med-gray: #cfd0d0; // warm, see $gray-slate-xlight for cooler version

$medium-gray: #b7c1c4; // F6: medium gray UI items
$dark-gray: #a6afb2; // F6: dark gray UI items
$light-ebony: #676c6f;
$ebony: #414143;
$onyx-black: #333333;
$black: #0f1011; // F6: black UI items

$transparent: rgba(0,0,0,0);
$white-transparent: rgba(254,254,254,0.9);

// ----------------------------------------
// Semantic-Named Variables
// ----------------------------------------

$shadow-color-light: rgba($black, 0.4);
$shadow-color-dark: rgba($black, 0.65);

$global-border-color: rgba($black, 0.25);
$global-box-shadow:
  0 2px 4px 0 rgba($black,0.16);


// ----------------------------------------
// Global Theme Colors for Healthy Source Webs
// ----------------------------------------

$feat-color: $blue-slate-dark;  // foundation-palette = $primary-color (links, default button)
$feat-color-light: $blue-slate;
$feat-background-color: $xlight-slate-blue; // foundation-palette = $secondary-color

$body-font-color: $onyx-black; // settings
$header-color: $feat-color;  // settings.4-base-typography
$buy-button-color: $grayed-jade-dark;

$dropdownmenu-arrow-color: $feat-color; // set here to enable changes in other webs

$alert-input-background-color: #fcebe8; // set by foundation, this is for my use
// alert-input-border-color = alert-color

// ----------------------------------------
// Top-bar Menu Colors
// ----------------------------------------
$menu-color-light: $grayed-jade-xlight;
$menu-color-dark: $grayed-jade-dark;  // success button color (aka $buy-button-color)

// -----------------------------------------
// Control Center for HealthySource.com
// ----------------------------------------

$cc-primary-color: #2a569b;  // see also page_cc.scss

// ----------------------------------------
// Background colors
// ----------------------------------------

$bkg: $gray99;

$bkg01: #efeee9;
$bkg02: $light-khaki;

$bkg0: $white;
$bkg1: $xxxlight-gray;
$bkg2: $gray84;
$bkg3: $gray-fawn;
$bkg4: $xlight-slate-blue;
$bkg5: $light-sky-blue;
$bkg6: $xlight-turquoise;
$bkg7: $gray-green;
$bkg8: $xxlight-turquoise;
$bkg9: $grayed-jade-light;

$bkg10: $light-lavendar;
$bkg11: $light-pastel-magenta-red;
$bkg12: $xlight-pink;
$bkg13: $light-mint-green;
$bkg14: $xlight-lavendar;
$bkg15: $xlight-denim-blue;
$bkg16: $light-denim-blue;
$bkg17: $pale-yellow;
$bkg18: $light-grass;
// the following are unassigned
//$bkg19:
//$bkg20:

$hover-background-color: $bkg;
$hover-background-alt: $bkg1;

$bkg-cur: $xlight-tangerine; // BioMor Curcumin
$bkg-cur-light: #fff5df;

// see colors-background for corresponding class-names


// ----------------------------------------
// Object colors
// ----------------------------------------

$disclaimer-bkg-color: $bkg;
$collapsible-object-color: $bkg;
$review-item-color: $bkg;


// ----------------------------------------
// Highlight (emphasis) colors
// ----------------------------------------

$highlight-yellow-bright: $yellow-highlight;
$highlight-yellow-soft: $yellow-highlight-soft;

$alert-color: $red;
$em-highlight-color: $highlight-yellow-bright;