.bkg {
  background: $bkg;
}

.bkg01 {
  background: $bkg01;
}
.bkg02 {
  background: $bkg02;
}

.bkg0 {
  background: $bkg0;
}
.bkg1 {
  background: $bkg1;
}

.bkg2 {
  background: $bkg2;
 }

.bkg3 {
  background: $bkg3;
}

.bkg4 {
  background: $bkg4;
}

.bkg5 {
  background: $bkg5;
}

.bkg6 {
  background: $bkg6;
}
.bkg7 {
  background: $bkg7;
}
.bkg8 {
  background: $bkg8;
}

.bkg9 {
  background: $bkg9;
}

.bkg10 {
  background: $bkg10;
}

.bkg11 {
  background: $bkg11;
}

.bkg12 {
  background: $bkg12;
}
.bkg13 {
  background: $bkg13;
}

.bkg14 {
  background: $bkg14;
}
.bkg15 {
  background: $bkg15;
}
.bkg16 {
  background: $bkg16;
}
.bkg17 {
  background: $bkg17;
}
.bkg18 {
  background: $bkg18;
}

.bkg19 {
  //background: $bkg19;
}

.bkg20 {
  //background: $bkg20;
}



.bkg-cur {
  background: $bkg-cur;
}

.bkg-cur-light {
  background: $bkg-cur-light;
}

.bkg-em {
  background-color: $em-highlight-color;
}

// bkg-rev (white font on black background, etc.)

@mixin bkg-rev {
  background: $body-font-color;
  color: $white;
  h1,h2,h3,h4,h5,h6 {
    color: $white;
  }
  @include anchor-colors($white, $white);
}

@mixin bkg-rev-feat {
  background: $feat-color;
  color: $white;
  h1,h2,h3,h4,h5,h6 {
    color: $white;
  }
  @include anchor-colors($white, $white);
}

.bkg-rev {
  @include bkg-rev;
}

.bkg-rev-feat,
.bkg-rev-feat:hover {
  @include bkg-rev-feat;
}

// removes color

code.bkg0 {
  background: $bkg0;
  border: $bkg0;
}

// for tables

tr.bkg6 td,
tr.bkg6 th {
  background: $bkg6;
}
tr.bkg7 td,
tr.bkg7 th {
  background: $bkg7;
}

// for table row background colors, see components/tables.scss
