@mixin ad-font-style {
  font-family: $font-family-sans-serif;
  color: $body-font-color;
}

.ad-pod {
  @include mb;
 @include ad-font-style;
  h1,h2,h3,h4,ul,ol,p,small {
     @include ad-font-style;
  }
}

.ad-ul {
  font-size: 125%;
  line-height: 1.6;
  width: max-content;
  margin: 1rem auto;
}
.ad-cta {
  margin: $global-margin;
  text-align: center;
 .button.hollow, .button.hollow:hover, .button.hollow:focus {
   background-color: $white;
 }
   .button.hollow {
     border: 1px solid $black;
   @include ad-font-style;
     border-radius: 0;
   }
 }



  h2 {
    @include breakpoint(large only) {
      font-size: 3rem;
    }
  }