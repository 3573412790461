.tel,
a.tel,
a:link.tel,
a:visited.tel,
a:hover.tel,
a:focus.tel {
  color: $menu-color-dark;
}


// for mobile devices
a [href^=tel] {
  color: inherit;
  text-decoration: none;
}

a.button [href^=tel] {
  color: $white;
}

h1, h2, h3, h4, h5, h6 {
  &.tel {
    font-family: $body-font-family;
  }
}
