// title-bar is for small
// top-bar is for medium-and-up

// see also: settings.top-bar
// see footer for .logo-slogan styles

$topbar-responsive-animation-type: none; // or use fade-in, slide-down or none
$top-bar-padding: 0.3rem;

.top-bar {
  @include flex;
  @include flex-align(justify, top);
  // constrain size of logo for tiny browsers
  @media screen and (max-width: 19em) {
    img.logo-hs {
      max-width: 180px;
    }
  }
}

.top-bar {
  .menu a {
    line-height: 1.2;
  }
}

.top-bar-title-bar {
  width: 100%;
  @include breakpoint(medium) {
    width: auto;
  }
  @include flex;
  @include flex-align(justify, top);

}
.top-bar-logo {
  padding: $top-bar-padding;
  vertical-align: middle;
  @include breakpoint(medium){
    vertical-align: top;
  }
}

.title-bar-menu {
  .title-bar-icon {
    background-color: $menu-color-dark;
    padding: 1.125rem 1.25rem;
    width: 4rem;
  }

}
.top-bar-right {
    font-size: rem-calc(20);
  @include breakpoint(medium) {
    padding: $top-bar-padding;
    ul {
      align-items: center;
      justify-content: flex-end;
    }
  }

}
.top-bar-right {
  .is-dropdown-submenu,
  .is-dropdown-submenu-parent {
    z-index: 2;
    box-shadow: 5px 5px 114px -46px rgba(99, 99, 98, 1);
  }
}

.top-bar {
  li,
  li.is-dropdown-submenu-parent {
    a:hover {
      background: $hover-background-color;
    }
  }
}

a.button.top-bar-button {
  padding: .85em;
  margin: 0 0 0 1rem;
  .drilldown & {
    background: $body-font-color;
    margin: 0.5rem;
  }
  .drilldown &.buy {
    background: $buy-button-color;

  }
}
.top-bar-right {
  .menu .button {
    display: inline-block;
  }
  //  with drill-down menu
  ul.vertical.menu li {
    @include breakpoint(small only) {
      &:hover {
        background: $hover-background-color;
      }
      &:hover.no-hover:hover {
        background: $white;

      }
      border-bottom: 2px solid $hover-background-color;
      padding: 0.3rem 0;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  // fix for F6.5
  .dropdown.menu > li > a.button.buy {
	background: $buy-button-color;
    //display: inline-block;
	margin: 0 0 0 1rem;
	padding: 0.85em;
	line-height: 1.2;
}
}



@keyframes fadeIn {
  from {
    opacity:0;
  } to {
      opacity:1;
    }
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@include breakpoint(small only) {
  .topbar-animation {
    animation-fill-mode: both;
    animation-duration: 0.5s;
    width: 100%;

    @if $topbar-responsive-animation-type == slide-down {
      animation: slideDown 0.3s ease-in;
    } @else if $topbar-responsive-animation-type == fade-in {
      animation: fadeIn 0.3s ease-in;
    } @else {
      animation: none;
    }
  }
}
