// https://alligator.io/css/collapsible/

.collapsible-object {
  margin: $global-margin 0;
  width: 100%;
}

input.collapsible-toggle[type='checkbox'] {
  display: none;
}

.collapsible-toggle-label {
  display: block;
  //font-family: $body-font-family;
  padding: 1rem;
  color: $anchor-color;
  cursor: pointer;
  border-radius: 0;
  transition: all 0.25s ease-out;

  background: $bkg;
  &.bkg4 {
    background: $bkg4;
  }
}



[type="checkbox"] + label.collapsible-toggle-label {
  display: block;
  margin: 0;
}

// removes blue outline in Chrome
[type="checkbox"] + label.collapsible-toggle-label:focus {
  outline: none;
}

.label-toggle:hover {
  color: $anchor-color-hover;
}

//animated arrow
.collapsible-toggle-label::before {
  content: ' ';
  @include css-triangle(rem-calc(9), $anchor-color, right);
  display: inline-block;
  vertical-align: middle;
  margin-right: .7rem;
  transform: translateY(-2px);
  transition: transform .2s ease-out;
}

.collapsible-toggle:checked + .collapsible-toggle-label::before {
  transform: rotate(90deg) translateX(-3px);
}

.collapsible-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height .25s ease-in-out;
}

.collapsible-toggle:checked + .collapsible-toggle-label + .collapsible-content {
  max-height: 10000px;
}

.collapsible-toggle:checked + .collapsible-toggle-label{
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.collapsible-content .collapsible-padding {
  background: $white;
  padding: .5rem 1rem;
}