table {
  border-collapse: collapse;
  @include table-hover;
  caption {
    text-align: left;
  }
}
td {
  vertical-align: top;
}
table td.td-valign-bottom {
  vertical-align: bottom;
}

table tbody tr:nth-of-type(odd),
.cell-color {
  background-color: $hover-background-color;
}

table.bkg3-cell-color {
  tbody tr:nth-of-type(odd),
  .cell-color {
    background-color: $bkg3;
  }
}

table.bkg4-cell-color {
  tbody tr:nth-of-type(odd),
  .cell-color {
    background-color: $bkg4;
  }
}

table.bkg6-cell-color {
  tbody tr:nth-of-type(odd),
  .cell-color {
    background-color: $bkg6;
  }
}

table.bkg10-cell-color {
  tbody tr:nth-of-type(odd),
  .cell-color {
    background-color: $bkg10;
  }
}

table.bkg-cur-cell-color {
  tbody tr:nth-of-type(odd),
  .cell-color {
    background-color: $bkg-cur-light;
  }

  // see also global/colors-background.scss
}

// fixes .table-scroll, should be for small-only (put on container of table)

.table-scroll-small-only {
  @include breakpoint(small only) {
    overflow-x: auto;
  }
  table {
    @include breakpoint(small only) {
      width: auto;
    }
  }
}


// Column Alignment Options (add to table element)
// ----------------------------

// last column: text-align-right

.col-last-1-right {
  td:last-child {
    text-align: right;
  }
}

// last two columns: text-align-right

.col-last-2-right  {
  @extend .col-last-1-right;
  td:nth-last-child(2) {
    text-align: right;
  }
}


// last three columns: text-align-right

.col-last-3-right  {
  @extend .col-last-2-right;
  td:nth-last-child(3) {
    text-align: right;
  }
}

// last 4 columns: text-align-right

.col-last-4-right  {
  @extend .col-last-3-right;
  td:nth-last-child(4) {
    text-align: right;
  }
}
// all columns: text-align-center

.col-center {
  td {
    text-align: center;
  }
}

// last column: text-align-center

.col-last-1-center {
  td:last-child {
    text-align: center;
  }
}

// last 2 columns: text-align-center

.col-last-2-center  {
  @extend .col-last-1-center;
  td:nth-last-child(2) {
    text-align: center;
  }
}
// last 3 columns: text-align-center

.col-last-3-center  {
  @extend .col-last-2-center;
  td:nth-last-child(3) {
    text-align: center;
  }
}
// last 4 columns: text-align-center

.col-last-4-center  {
  @extend .col-last-3-center;
  td:nth-last-child(4) {
    text-align: center;
  }
}

// last column: text-align-right

.col-first-1-right {
  td:first-child {
    text-align: right;
  }
}

