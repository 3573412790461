// Horizontal Rule Styles
// ------------------------------------------

@mixin hr-dot (
  $color: (scale-color($body-font-color, $lightness: 40%)),
  $style: dotted,
  $width: 2px
) {
  border-bottom-color: $color;
  border-bottom-style: $style;
  border-bottom-width: $width;
}

hr {
  border-bottom: $global-border;
  &.hr-dot {
    @include hr-dot;
  }
  &.hr-dot-cur {
    @include hr-dot($tangerine);
  }
}

