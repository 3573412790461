// Border-Helpers
// -------------------------------------------

$global-border: 1px solid $global-border-color;

// Border-Helper Mixins
// ----------------------------------------

@mixin b {
  border: $global-border;
}

@mixin bt {
  border-top: $global-border;
}

@mixin bb {
  border-bottom: $global-border;
}

@mixin no-border {
  border: 0 $transparent;
}

// Border-Helper Classes
// ----------------------------------------

%b,
.b {
  @include b;
}

%bt,
.bt {
  @include bt;
}

%bb,
.bb {
  @include bb;
}

// 2px border
$border-2: 2px solid (scale-color($global-border-color, $lightness: 30%));


@mixin bt2 {
  border-top: $border-2;
}

@mixin bb2 {
  border-bottom: $border-2;
}


%bt2,
.bt2 {
  @include bt2;
}

%bb2,
.bb2 {
  @include bb2;
}

%b2,
.b2 {
  border: $border-2;
}
table.b2 td,
table.b2 th {
  border: $border-2;
}

.black-box {
      border: 2px solid $body-font-color;
    }