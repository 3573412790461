.blog-masthead-banner {
  margin: 0 auto;
  h1 {
    margin: 0;
  }
}

.blog-post {
  margin: ($global-margin*4) 0;
  justify-content: center;
}

.blog-date {
  color: $header-small-font-color;
  //font-size: .8125rem;
  margin: $global-margin 0;
}

.blog-more {
  margin: ($global-margin*2) 0 0 0;
  list-style: none;
padding: 0 0 $global-margin 0;
}

.blog-masthead-pod {
  position: relative;
}
.blog-hero-overlay img {
  position: absolute;
  margin: auto;
  top: 0; left: 0; bottom: 0; right: 0;
}
@include breakpoint(medium) {
  .feat-hero-overlay img {
    top: 40px;
    left: 10px;
  }
}

@include breakpoint(large) {
  .feat-hero-overlay img {
    top: 30px;
    left: 5px;
    width: 640px;
  }
}

