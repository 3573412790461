// place on the container of SVG
.svg-100w {
  max-width: 100px; //resize SVG
  display: inline-block;

  //fix (iOS) to make SVG responsive
  > img {
    max-width: 100px;
  }
}
.svg-200w {
  max-width: 200px; //resize SVG
  display: inline-block;

  //fix (iOS) to make SVG responsive
  > img {
    max-width: 200px;
  }
}
.svg-300w {
  max-width: 300px; //resize SVG
  display: inline-block;
  //fix (iOS) to make SVG responsive
  > img {
    max-width: 300px;
  }
}
.svg-400w {
  max-width: 400px;
  display: inline-block;
    //fix (iOS) to make SVG responsive
  > img {
    max-width: 400px;
    @include breakpoint(small) {
      max-width: 100%;
    }
  }
}

.svg-640w {
  max-width: 640px;
  display: inline-block;
    //fix (iOS) to make SVG responsive
  > img {
    max-width: 640px;
    @include breakpoint(small) {
      max-width: 100%;
    }
  }
}

.svg-400-640w {
  max-width: 400px;
  display: inline-block;
  //fix (iOS) to make SVG responsive
  > img {
    max-width: 400px;
    @include breakpoint(small) {
      max-width: 100%;
    }
  }
  @include breakpoint(medium) {
    max-width: 640px;
    //fix (iOS) to make SVG responsive
    > img {
      max-width: 640px;
    }
  }
}

.svg-200-400w {
  max-width: 200px;
  display: inline-block;
  //fix (iOS) to make SVG responsive
  > img {
    max-width: 200px;
    @include breakpoint(small) {
      max-width: 100%;
    }
  }
  @include breakpoint(medium) {
    max-width: 400px;
    //fix (iOS) to make SVG responsive
    > img {
      max-width: 400px;
    }
  }
}

.svg-120-200w {
  max-width: 120px;
  display: inline-block;
  //fix (iOS) to make SVG responsive
  > img {
    max-width: 120px;
    @include breakpoint(small) {
      max-width: 100%;
    }
  }
  @include breakpoint(medium) {
    max-width: 200px;
    //fix (iOS) to make SVG responsive
    > img {
      max-width: 200px;
    }
  }
}

// hack to fix IE svgs
// IE 10 & 11
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .svg {
    width: 100%;
  }
}
// IE 12
@supports (-ms-accelerator:true) {
  .svg {
    width: 100%;
  }
}

// [class^="svg"]

// apply shadow to svg element
.svg-shadow {
    -webkit-filter: drop-shadow( 5px -5px 10px $body-font-color);
            filter: drop-shadow( 5px -5px 10px $body-font-color);
  .green {
    -webkit-filter: drop-shadow( 5px -5px 10px #546429 );
            filter: drop-shadow( 5px -5px 10px #546429 );
  }
}