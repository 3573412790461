// MUST @import at top of settings
// ----------------------------------------

// --- font family

$font-family-serif: Georgia, Cambria, "Times New Roman", Times, serif;
$font-family-sans-serif: "Helvetica Neue-Regular", "Helvetica Neue Regular", "Helvetica Neue", "Helvetica", "Arial", "Lucida Grande", "sans-serif";

$small-type-font-family: "Lucida Sans Unicode","Lucida Grande","Helvetica Neue","Helvetica","Arial","sans-serif";

// $font-family-monospace @ settings

// --- font weight

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-medium-bold: 600;
$font-weight-bold: 700;
$font-weight-black: 900;

// --- other global typography

h1, h2 {
  letter-spacing: -0.3px;
}

