// -------------------------------------------
// Footnotes
// -------------------------------------------

// References

ul.refs {
  list-style: none;
  margin-left: 0;
  li {
    text-indent: - ($global-margin * 1.1);
    margin-left: ($global-margin * 1.2);
  }
}
.ref.arrow--down {
  margin-left: .3rem;
}


// -------------------------------------------
// Pre-Footer Objects
// -------------------------------------------

// References

.footnotes {
  margin-right: 1rem;
}

.footnotes ul.refs ul.menu {
  @include mb;
}

// Disclaimers

.disclaimer {
  //@include b;
  //background: $bkg;
  @include small-type (rem-calc(12.5));
  padding: ($global-padding/2) $global-padding;
}

// -------------------------------------------
// Footer Objects
// -------------------------------------------

footer {
  margin-top: 1rem;
  .logo-block {
    @include breakpoint(small only) {
      text-align: center;
      @include mt;
    }
    @include breakpoint(medium) {
      text-align: right;
    }
  }
  .button {
    @include m;
    @include breakpoint(medium) {
      margin: 0 0 $global-margin 0;
    }
  }

  // Footnotes at bottom (includes copyright)

  .fn {
@include ph;
    ul {
      @include small-type (rem-calc(12.5));
      list-style: none;
      margin-left: 0;
    }
  }
}

// logo-slogan styles also affect logo-slogan in top-bar
.logo-slogan {
  font-size: .8rem;
  font-family: $small-type-font-family;
  .top-bar &  {
    margin-left: 0.2rem;
  }
}
ul.logo-slogan {
  list-style: none;
  font-weight: $global-weight-bold;
  @include breakpoint(small only) {
    margin: 0;
  }
}

