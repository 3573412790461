//  padding-Helpers
// ----------------------------------------

$global-column-padding: .625rem;

.cell-padding-x > p {
  padding-right: $global-column-padding;
  padding-left: $global-column-padding;
}

//  padding-Helper Mixins
// ----------------------------------------

@mixin p {
   padding:$global-padding;
}
@mixin pt {
   padding-top:$global-padding;
}
@mixin pr {
   padding-right:$global-padding;
}
@mixin pb {
   padding-bottom:$global-padding;
}
@mixin pl {
   padding-left:$global-padding;
}
@mixin ph {  //  padding-horizontal
   padding-right:$global-padding;
   padding-left:$global-padding;
}
@mixin pv {  //  padding-vertical
   padding-top:$global-padding;
   padding-bottom:$global-padding;
}


//  padding-Helper Classes
// ----------------------------------------

%p,
.p {
  @include p;
}
%pt,
.pt {
  @include pt;
}
%pr,
.pr {
  @include pr;
}
%pb,
.pb {
  @include pb;
}
%pl,
.pl {
  @include pl;
}
%ph,
.ph {
  @include ph;
}
%pv,
.pv {
  @include pv;
}
