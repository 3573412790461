.float-left {
  float: left;
  padding: 0 $global-padding 0 0;
}
.float-right {
  float: right;
  padding: 0 0 0 $global-padding;
}

img {
  &.float-right {
    margin-left: $global-margin;
    margin-bottom:$global-margin;
    text-align: right;
  }
  &.float-left {
    margin-right: $global-margin;
    margin-bottom:$global-margin;
  }
}

.float-left,
.float-right {
  &.small-4 {
    @include breakpoint(small only){
      width: 33.33333%;
    }
  }
  &.large-6 {
    @include breakpoint(large){
      width: 50%
    }
  }
}

