// change colors for biomorcurcumin.com

// page banners

div.page-banner {
  h1, h2, h3, h4, h5 {
    background-color: $body-font-color;
  }
}

a:hover, a:focus {
      color: $anchor-color;
    }

// for sup (TM) symbols

.page-curcumin {
    h1, h2, h3, h4, h5 {
      @include anchor-colors;

  a sup {
      color: $anchor-color;
    }
    sup {
      color: $header-color;
    }
  }

  .blog-date {
    color: $anchor-color;
  }
}

 a:hover {
      @include anchor-underscore($anchor-color, dotted, 1px, $transparent)
    }

 // fix color of dropdown arrows in top-bar

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
	border-top-color: $feat-color;
}