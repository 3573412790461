.color-block-cell {
  flex: 0 0 auto;
  min-height: 0;
  min-width: 0;
  width: 50%;
}
@media screen and (max-width: 320px) {
  .color-block-cell {
    width: 100%;
    &.small-order-1 {
      order: 2
    }
    &.small-order-2 {
      order: 1
    }
  }
}