// @include css-triangle($triangle-size, $triangle-color, $triangle-direction)
// http://foundation.zurb.com/sites/docs/sass-mixins.html
// uses double-hyphen to avoid conflict

.arrow--up {
  @include css-triangle(rem-calc(6), $anchor-color, up);
  display: inline-block;
}

.arrow--down {
  @include css-triangle(rem-calc(6), $anchor-color, down);
  display: inline-block;
}

.arrow--right {
  @include css-triangle(rem-calc(6), $anchor-color, right);
  display: inline-block;
}

.arrow--left {
  @include css-triangle(rem-calc(6), $anchor-color, left);
  display: inline-block;
}
