// --- anchor tags

@mixin anchor-colors (
  $link: $anchor-color,
  $active: scale-color($anchor-color, $lightness: -14%)) {
  a {
    color: $link;
    &:visited,
    &:hover,
    &:active {
      color: $active;
    }
  }
}

a abbr {
  color: $anchor-color;
  &:visited,
  &:hover,
  &:active {
    color: $anchor-color;
  }
}

// @include anchor-colors ($header-color, $header-color);

// Underscore anchor tags

@mixin anchor-underscore (
  $color: (scale-color($anchor-color, $lightness: 40%)),
  $style: dotted,
  $width: 2px,
  $background-color: (scale-color($anchor-color, $lightness: 95%))
) {
  border-bottom-color: $color;
  border-bottom-style: $style;
  border-bottom-width: $width;
  background-color: $background-color;
}
a.u {
  @include anchor-underscore;
}

.bkg-cur a.u {
  background-color: transparent;
}