.fieldset {
  padding: 0.3rem;
  border-radius: $global-radius;
}

label {
  @include small-type (
          $small-type-font-size: (rem-calc(13)),
          $small-type-color: $body-font-color
  );
// fixes font-family for input inside label tag:
 [type="text"], [type="password"], [type="date"], [type="datetime"], [type="datetime-local"], [type="month"], [type="week"], [type="email"], [type="number"], [type="search"], [type="tel"], [type="time"], [type="url"], [type="color"], textarea, select {
    font-family: $body-font-family;
  }
}

// style a tags in alert boxes

.callout.alert {
  @include anchor-colors (
                  $body-font-color, $body-font-color);
}

select.input-bold,
[type="text"].input-bold {
  border: 1px solid $dark-gray;

  @include breakpoint(medium) {
    font-size: 1.25rem;
  }
}

input[type="checkbox"].round-checkbox {
  width: 1.3rem;
  height: 1.3rem;
  border: 1px solid $medium-gray;
  border-radius: 50%;
  background-color: $white;
  box-shadow: inset 0 1px 2px rgba($black, 0.1);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:checked {
    background-color: $bkg7;
    border: 1px solid $dark-gray;

  }
}