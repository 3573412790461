.hero-add2cart-form {
  .add2cart {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
  }

  .input-number-group {
    width: 190px;
    //margin-right: $global-margin;
  }

  .input-group-button-label,
  .input-group-submit {
    flex: 0 0 auto;
  }

  .input-group-submit .button {
      margin: 0 0 .5rem 0;
  }
}

.customer-reviews {
  @include mv;
  //color: $hs-logo-orange;
  //@include anchor-colors($hs-logo-orange, $hs-logo-orange);
  i.fa-star {
    color: $hs-logo-orange;
  }
}