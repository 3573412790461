// Typography Helpers
// -------------------------------------------

small {
  font-size: 60%;
}

.off-focus {
  color: scale-color($body-font-color, $lightness: 30%);
}

// Small-Type (for disclaimers and references)
// -------------------------------------------

@mixin small-type (
  $small-type-font-size: rem-calc(13),
  $small-type-color: scale-color($body-font-color, $lightness: 20%),
  $small-type-weight: $font-weight-light) {
  font-size: $small-type-font-size;
  color: $small-type-color;
  font-weight: $small-type-weight;
  font-family: $small-type-font-family;
}

sub,
sup,
.refs {
  @include small-type (rem-calc(11));
}

.small-type {
  @include small-type (rem-calc(13));
}

// for trademark registration symbol:

h1, h2, h3, h4, h5 {
  @include anchor-colors (
                  $header-color, $header-color);
  small {
    font-size: 40%;
    color: $header-color;
  }
//  small,
//  a small {
//    color: $header-color;
//  }
}


// Text-Effects (glows, shadows)
// -------------------------------------------

@mixin text-glow-dark (
  $color: $white,
  $text-shadow: 0 0 30px $shadow-color-dark) {
    color: $color;
    text-shadow: $text-shadow;
}

// serif and sans-serif

.s {
  font-family: $font-family-serif;
}
.ss {
  font-family: $font-family-sans-serif;
}
