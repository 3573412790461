.feat-title { //page-title
  margin: 0;
  @include flex;
  @include flex-align(center, middle);
}
.feat-subtitle { //page-subtitle
  text-align: center;
  margin: 0;
  line-height: 1.5;
}

.feat-hdr {
    text-align: center;
  line-height: 1.2;
  margin: ($global-margin *2) 0;
}

.feat-graphic {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  margin: ($global-margin *4) 0;
}

.feat-caption {
  text-align: left;
  @include p;
  &.text-right {
    text-align: right;
  }
}

.feat-cta {
  margin: $global-margin 0;
  .hollow.button {
    border-radius: 0;
    border: 1.5px solid $header-color;
    color: $header-color;
    padding: 0.7rem 1rem;
    margin: 0 .5rem;
  }
  .hollow.button.large {
    border: 1.5px solid $header-color;
  }
  .button.dropdown.hollow.more-info::after {
    border-top-color: $header-color;
  }
  width: 100%;
  @include flex;
  @include flex-align(center, middle);
}

.feat-ul,
.feat-ol {
  @include mv;
  li {
    @include mv;
  }
}

.feat-hero-pod {
  position: relative;
}
.feat-hero-overlay img {
  position: absolute;
  top: 10px;
  width: 50%;

}
@include breakpoint(medium) {
  .feat-hero-overlay img {
    top: 40px;
    left: 10px;
  }
}

@include breakpoint(large) {
  .feat-hero-overlay img {
    top: 30px;
    left: 5px;
    width: 640px;
  }
}
