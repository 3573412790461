// for hexagons (4 key actions of Flavay)
// 1 - collagen
// 2 - anti-inflammatory
// 3 - nitric-oxide
// 4 - antioxidant

$hex-bkg-1: $light-sky-blue;
$hex-bkg-2: $grayed-jade-light;
$hex-bkg-3: $light-pastel-magenta-red;
$hex-bkg-4: $light-lavendar;

.hex-icons {
  margin: $global-margin 0;
  .hex-icons-img {
    margin: 0.2rem 0.5rem;
    @include flex;
    @include flex-align(center, middle);
  }

  em {
    @include em\+;
  }

  .hex1 {
    background: $bkg1;
    em {
      background: $hex-bkg-1;
    }
  }

  .hex2 em {
    background: $hex-bkg-2;
  }
  .hex3 {

    em {
      background: $hex-bkg-3;
    }
  }
  .hex4 {
    background: $bkg1;
    em {
      background: $hex-bkg-4;
    }
  }
}





