// Typography Emphasis and Alerts
// ----------------------------------------

// Emphasis
// ----------------------------------------

$em-font-size: (130%);

@mixin em ($em-font-size: $em-font-size,
$em-font-style: normal,
$em-font-weight: $font-weight-medium){
  font-size: $em-font-size;
  font-style: $em-font-style;
  font-weight: $em-font-weight;
}


.em {
  @include em;
  background: $em-highlight-color;
}

.em0 {
  @include em;
  background: $transparent;
}

@mixin em\+ {
  @include em (160%);
  background: $em-highlight-color;
}
@mixin em0\+ {
  @include em (160%);
  background: $transparent;
}

.em\+ {
  @include em\+;
}
.em0\+ {
  @include em0\+;
}

// Emphasis-Alert (red text)
// ----------------------------------------

%em-alert,
.em-alert,
a.em-alert,
.em-alert a,
.em-alert small,
.em-alert sup {
    color: $alert-color !important;
}

// Bold

b, strong {
  font-weight: $font-weight-bold;
}
