// hack to fix IE images to make them responsive
// IE 10 & 11
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  [src$=".jpg"],
  [src$=".png"] {
    width: 100%;
  }
  h1.feat-title {
    [src$=".png"] {
    width: 640px;
  }
  }
}
// IE 12
@supports (-ms-accelerator:true) {
  [src$=".jpg"],
  [src$=".png"] {
    width: 100%;
  }
  h1.feat-title {
    [src$=".png"] {
      width: 640px;
    }
  }
}
