ul.nav-bubbles {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem;

  li {
    list-style-type: none;
    margin: 3px 1px;
    padding: 8px 2px;
    text-overflow: ellipsis;
    display: inline-block;
  }

  li a {
    padding: 7px 12px;
    border: 2px solid scale-color($global-border-color, $lightness: 40%);
    background: rgba(255, 255, 255, 0.3);
    border-radius: 24px;
    cursor: pointer;
    @include small-type((rem-calc(13)), $body-font-color, $font-weight-medium);

    line-height: 1.4rem;

    &:hover {
      color: $anchor-color-hover;
    }
  }

  // for bolder borders on all bubbles
  &.nav-bubbles-cta {
    li a,
    li.nav-bubble-cta a {
      border-color: $global-border-color;
    }
  }
}


// for bolder background color on single bubble (print bubbles)
.nav-bubbles li.nav-bubble-cta a {
  background-color: scale-color($global-border-color, $lightness: 70%);
}
