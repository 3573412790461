// Simple width-helper classes (e.g., w-400)

.w-40 {
  width: 40px;
}
.w-60 {
  width: 60px;
}
.w-80 {
  width: 80px;
}
.w-100 {
  width: 100px;
}
.w-200 {
  width: 200px;
}
.w-300 {
  width: 300px;
}
.w-1024 {
    margin: 0 $global-margin;
  }

@include breakpoint(medium) {
  .w-400 {
    width: 400px;
  }
  .w-640 {
    width: 640px;
  }
}
@include breakpoint(large) {
  .w-1024 {
    width: 1024px;
  }
}
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
