// MUST @import at top of blog-hs66.scss

// ----------------------------------------
// Default Settings
// ----------------------------------------
$masthead-color: $feat-color;
$masthead2-color: $white;


// ----------------------------------------
// curcumin-turmeric.net and biomorcurcumin.com
// ----------------------------------------
$blog-curcumin-color: #f67c00;
$feat-color: #925730;
$dropdownmenu-arrow-color: $feat-color;
$header-color: $body-font-color;
$masthead-color: $blog-curcumin-color;
$masthead2-color: $white;
$global-border-color: #d8d8d8;


// ----------------------------------------
// opcs.com
// ----------------------------------------
//$feat-color: #801214;
//$feat-background-color: #f5f4ef;
//$header-color: $body-font-color;
//$masthead-color: $feat-color;
//$masthead2-color: $feat-background-color;
//$global-border-color: #d2d0d1;
//
//$disclaimer-bkg-color: $bkg01;
//$collapsible-object-color: $bkg01;
//$review-item-color: $bkg01;
//
//$em-highlight-color: $yellow-highlight;

// ----------------------------------------
// phosphatidylserine.net
// ----------------------------------------
//$feat-color: #4367B1;
//$masthead-color: $feat-color;
//$masthead2-color: $white;
//$global-border-color: $light-denim-blue;



// ----------------------------------------
// HealthyAlternatives.com
// ----------------------------------------

//$feat-color: $darker-green-cyan;
//$feat-background-color: $xlight-slate-blue;
//
//$body-font-color: $onyx-black;
//$header-color: $feat-color;
//
//$alert-input-background-color: #fcebe8; // (set by foundation, this is for my use in a form-box) NOTE: $alert-input-border-color = $alert-color;



