// Margin-Helpers
// ----------------------------------------

// Margin-Helper Mixins
// ----------------------------------------

@mixin m {
  margin:$global-margin;
}
@mixin mt {
  margin-top:$global-margin;
}
@mixin mr {
  margin-right:$global-margin;
}
@mixin mb {
  margin-bottom:$global-margin;
}
@mixin ml {
  margin-left:$global-margin;
}
@mixin mh {  // margin-horizontal
  margin-right:$global-margin;
  margin-left:$global-margin;
}
@mixin mv {  // margin-vertical
  margin-top:$global-margin;
  margin-bottom:$global-margin;
}
@mixin m\+ {  // .m++ = margin x2
  margin:($global-margin * 2);
}
@mixin mt\+ {
  margin-top:($global-margin * 2);
}
@mixin mr\+ {
  margin-right:($global-margin * 2);
}
@mixin mb\+ {
  margin-bottom:($global-margin * 2);
}
@mixin ml\+ {
  margin-left:($global-margin * 2);
}
@mixin mh\+ {
  margin-right:($global-margin * 2);
  margin-left:($global-margin * 2);
}
@mixin mv\+ {
  margin-top:($global-margin * 2);
  margin-bottom:($global-margin * 2);
}
@mixin m\+\+ {  // .m++ = margin x4
  margin:($global-margin * 4);
}
@mixin mt\+\+ {
  margin-top:($global-margin * 4);
}
@mixin mr\+\+ {
  margin-right:($global-margin * 4);
}
@mixin mb\+\+ {
  margin-bottom:($global-margin * 4);
}
@mixin ml\+\+ {
  margin-left:($global-margin * 4);
}
@mixin mh\+\+ {
  margin-right:($global-margin * 4);
  margin-left:($global-margin * 4);
}
@mixin mv\+\+ {
  margin-top:($global-margin * 4);
  margin-bottom:($global-margin * 4);
}
@mixin m0 {  // remove all margins
  margin:0 !important;
}
@mixin mt0 {
  margin-top:0 !important;
}
@mixin mr0 {
  margin-right:0 !important;
}
@mixin mb0 {
  margin-bottom:0 !important;
}
@mixin ml0 {
  margin-left:0 !important;
}
@mixin mh0 {  // remove horizontal margins
  margin-right:0 !important;
  margin-left:0 !important;
}
@mixin mv0 { // remove vertical margins
  margin-top:0 !important;
  margin-bottom:0 !important;
}

// Margin-Helper Classes
// ----------------------------------------

%m,
.m {
  @include m;
}
%mt,
.mt {
  @include mt;
}
%mr,
.mr {
  @include mr;
}
%mb,
.mb {
  @include mb;
}
%ml,
.ml {
  @include ml;
}
%mh,
.mh {
  @include mh;
}
%mv,
.mv {
  @include mv;
}
%m\+,
.m\+ {
  @include m\+;
}
%mt\+,
.mt\+ {
  @include mt\+;
}
%mr\+,
.mr\+ {
  @include mr\+;
}
%mb\+,
.mb\+ {
  @include mb\+;
}
%ml\+,
.ml\+ {
  @include ml\+;
}
%mh\+,
.mh\+ {
  @include mh\+;
}
%mv\+,
.mv\+ {
  @include mv\+;
}
%m\+\+,
.m\+\+ {
  @include m\+\+;
}
%mt\+\+,
.mt\+\+ {
  @include mt\+\+;
}
%mr\+\+,
.mr\+\+ {
  @include mr\+\+;
}
%mb\+\+,
.mb\+\+ {
  @include mb\+\+;
}
%ml\+\+,
.ml\+\+ {
  @include ml\+\+;
}
%mh\+\+,
.mh\+\+ {
  @include mh\+\+;
}
%mv\+\+,
.mv\+\+ {
  @include mv\+\+;
}
%m0,
.m0 {
  @include m0;
}
%mt0,
.mt0 {
  @include mt0;
}
%mr0,
.mr0 {
  @include mr0;
}
%mb0,
.mb0 {
  @include mb0;
}
%ml0,
.ml0 {
  @include ml0;
}
%mh0,
.mh0 {
  @include mh0;
}
%mv0,
.mv0 {
  @include mv0;
}


