.hero-add2cart-form {
  @include flex;
  @include flex-align(left, bottom);
}

$input-number-size: 80px;

.input-number-group {
  //@include flex;
 //@include flex-align(center, middle);

  .input-group-button {
    line-height: calc(#{$input-number-size}/2 - 5px);
  }

  .input-number {
    width: $input-number-size;
    padding: 0 12px;
    text-align: center;
    outline: none;
    display: block;
    margin: 0;
    background: $bkg;
    font-weight: $font-weight-regular;
    font-size: 1.25rem;
  }

  .input-number,
  .input-number-decrement,
  .input-number-increment {
    height: $input-number-size/2;
    user-select: none;
    border-radius: $global-radius;

  }

  .input-number-decrement,
  .input-number-increment {
    display: inline-block;
    width: $input-number-size/2;
    background: $feat-color;
    color: $xxxlight-gray;
    text-align: center;
    font-weight: $font-weight-bold;
    font-size: 2rem;
    cursor: pointer;

    &.circle {
      //border-radius: 80%;
      padding: .1rem;
    }

  }

  .input-number-decrement {
    margin-right: 0.3rem;
  }
  .input-number-increment {
    margin-left: 0.3rem;
  }

  // hide spinners: https://css-tricks.com/numeric-inputs-a-comparison-of-browser-defaults/
  .input-number {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type=number] {
      -moz-appearance:textfield;
    }
  }
}

