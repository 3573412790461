// page-cs = customer service page
// Note: .page-* must be used for body class names ONLY because: our body class names are defined in front matter and the problem is that it's then ignored by UnCSS; the fix is to exclude the prefix globally at config.yml)

.cs-quick-links {
  background-color: $bkg1;
  padding: $global-padding;
   @include anchor-colors ($blue-slate, $blue-slate);
  ul {
    list-style: none;
    margin-left: ($global-margin/2);
    margin-top: $global-margin;
    li {
      margin-bottom: $global-margin;
      padding-bottom: $global-padding;
      border-bottom: 2px solid $bkg7;
      h4 {
        margin-left: -($global-margin/2);
        margin-bottom: ($global-margin/2);
      }
      &:nth-child(1),
      &:nth-last-child(1) {
        border-bottom: 0;
      }
    }
  }
}



