$banner-padding: ($global-padding / 3) $global-padding;

@mixin global-banner {
  background-color: $blue-slate-dark;
  text-align: center;
  color: $white;
  font-family: $font-family-sans-serif;
  margin: 0;
  padding: $banner-padding;
}
// in the following declaration block, the div tag is added for css weight issues caused by .page--alt-color

div.page-banner {
  h1, h2, h3, h4, h5 {
    @include global-banner;
    @include anchor-colors (
                    $white, $white);
    small {
      color: $white;
    }
  }
}

