.nav-feat-benefits {
  display: flex;
  align-items: center;

}

.nav-feat-benefits-img {
  padding: $global-padding 0;
  img {
    @include breakpoint(medium down) {
      width: 100px;
    }
  }
}
.nav-feat-benefits-txt {
  flex: 1;
  padding: 0 $global-padding;
  h3 {
    font-size: 140%;
    line-height: 1;
  }
}
