h4.review-banner {
  margin: 0 0 1rem 0;
  padding: 1rem;
  background: $review-item-color;
  border-radius: $global-radius;
}

.review-blocks {
  column-count: 1;
  column-gap: $global-padding;

  @include breakpoint(400px) {
    column-count: 2;
  }

  @include breakpoint(600px) {
    column-count: 3;
  }

  @include breakpoint(800px) {
    column-count: 4;
  }

  @include breakpoint(1100px) {
    column-count: 5;
  }
  .review-item {
    width: 100%;
    display: inline-block;
    padding: $global-padding;
    background: $review-item-color;
    border: 1px solid transparent;
    border-radius: $global-radius;
    margin-bottom: ($global-margin*1.4);
    p {
      font-size: .875em;
      line-height: 1.5;
    }
  }
}

  .review-callout {
	margin: $global-margin 0;
	padding: $global-padding;
  border-radius: $global-radius;
}

@media print {
  .review-blocks .review-item {
    margin-bottom: 0;
    p {
    font-size: 130%;
  }
  }
}

.feat-reviews {
  column-count: 1;
  column-gap: $global-padding;
  @include breakpoint(medium) {
    column-count: 2;
  }
  .review-item {
    width: 100%;
    display: inline-block;
    padding: $global-padding;
    background: $review-item-color;
    border: 1px solid transparent;
    border-radius: $global-radius;
    margin-bottom: ($global-margin*1.4);
     @include breakpoint(medium) {
    margin-bottom: 0;
  }
  }
}