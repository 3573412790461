$bkg-feat: rgba(201, 250, 255, 0.63);

.callout-inner {
  &.bkg-feat {
  background-color: $bkg-feat;
    border-color: $bkg-feat;
}
  &.bkg-body {
    background-color: $body-background;
    border-color: $body-background;
  }
  &.bkg {
    background: $bkg;
    border-color: $bkg;
  }
  &.bkg0 {
    background: $bkg0;
    border-color: $bkg0;
  }
  &.bkg1 {
    background: $bkg1;
    border-color: $bkg1;
  }
  &.bkg2 {
    background: $bkg2;
    border-color: $bkg2;
  }
  &.bkg3 {
    background: $bkg3;
    border-color: $bkg3;
  }
  &.bkg4 {
    background: $bkg4;
    border-color: $bkg4;
  }
  &.bkg5 {
    background: $bkg5;
    border-color: $bkg5;
  }
  &.bkg6 {
    background: $bkg6;
    border-color: $bkg6;
  }
  &.bkg10 {
    background: $bkg10;
    border-color: $bkg10;
  }
  &.bkg11 {
    background: $bkg11;
    border-color: $bkg11;
  }
  &.bkg12 {
    background: $bkg12;
    border-color: $bkg12;
  }
    &.bkg13 {
    background: $bkg13;
    border-color: $bkg13;
  }

    &.bkg14 {
    background: $bkg14;
    border-color: $bkg14;
  }
  &.bkg-cur {
    background: $bkg-cur;
    border-color: $bkg-cur;
  }
  &.bkg-rev {
    background: $ebony;
    color: $white;
    h1,h2,h3,h4,h5,h6 {
      color: $white;
    }
    @include anchor-colors($white, $white);
    border-color: $body-font-color;
  }
  &.bkg-blue {
    background: $blue-slate-dark;
    color: $white;
    h1,h2,h3,h4,h5,h6 {
      color: $white;
    }
    border-color: $blue-slate-dark;
  }
  &.bkg-blue-royal {
    background: $blue-royal;
    color: $white;
    h1,h2,h3,h4,h5,h6 {
      color: $white;
    }
    border-color: $blue-royal;
  }
}

// black box

.callout {
  &.black-box {
    border-color: $body-font-color;
    border-width: 2px;
  }
}