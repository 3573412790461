.small-order-7 {
  order: 7;
}
.small-order-8 {
  order: 8;
}
.small-order-9 {
  order: 9;
}
.small-order-10 {
  order: 10;
}
.small-order-11 {
  order: 11;
}
.small-order-12 {
  order: 12;
}