// page-feat = feature page
// Note: .page-* must be used for body class names ONLY because: our body class names are defined in front matter and the problem is that it's then ignored by UnCSS; the fix is to exclude the prefix globally at config.yml)

// larger font-size for tablets and up
.page-feat {
  p,
  .feat-ul,
  .feat-ol,
  .feat-table td {
    @include breakpoint(medium) {
      font-size: 130%;
      margin-bottom: $global-margin *1.8;
    }
  }
  p {
    @include breakpoint(medium only) {
      line-height: 1.5;
    }
  }
}
@media print {
  .page-feat {
    p, ul, ol, h1, h2, h3, h4, h5 {
      font-size: 100%;
    }
  }
}